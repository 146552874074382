const appConfig = {
  apiPrefix: "/api",
  authenticatedEntryPath: "/dashboard",
  companyDashboard: "/dashboard/company",
  otherEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  enableMock: false,
};

export default appConfig;
