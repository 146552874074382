import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  avatar: "",
  username: "",
  email: "",
  name: "",
  id: "",
  authority: [],
  openModal: false,
  openProfileModal: false,
  openUpdateLogModal: false,
  openVersionModal: false,
};

export const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setOpenModal: (state, action) => {
      state.openModal = action.payload;
    },
    setOpenProfileModal: (state, action) => {
      state.openProfileModal = action.payload;
    },
    setOpenVersionModal: (state, action) => {
      state.openVersionModal = action.payload;
    },
    setOpenUpdateLogModal: (state, action) => {
      state.openUpdateLogModal = action.payload;
    },

    setUser: (_, action) => action.payload,
    userLoggedOut: () => initialState,
  },
});

export const {
  setOpenProfileModal,
  setOpenModal,
  setUser,
  setOpenUpdateLogModal,
  setOpenVersionModal,
} = userSlice.actions;
export default userSlice.reducer;
