import React from "react";
import CalendarBase from "./CalendarBase";
import { isSameDate } from "./utils";

const Calendar = (props) => {
  const { multipleSelection, value, onChange, calendarFormat, ...rest } = props;

  const handleChange = (date) => {
    if (calendarFormat === "month_year") {
      onChange(date);
    } else if (!multipleSelection) {
      return onChange(date);
    }
    if (calendarFormat !== "month_year") {
      const isSelected = value.some((val) => isSameDate(val, date));

      return onChange(
        isSelected
          ? value.filter((val) => !isSameDate(val, date))
          : [...value, date]
      );
    }
  };

  return (
    <CalendarBase
      onChange={handleChange}
      value={value}
      {...rest}
      calendarFormat={calendarFormat}
    />
  );
};

export default Calendar;
