import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Table,
  Tspan,
} from "@react-pdf/renderer";
import TickIcon from "../../../assets/images/tick_icon.png";

// Font.register({
//   family: "Calibri-Light",
//   src: require("../fonts/Calibri Light.ttf"),
// });

// Font.register({
//   family: "Calibri",
//   src: require("../fonts/Calibri-Regular.ttf"),
// });

// Font.register({
//   family: "Cambria",
//   src: require("../fonts/Cambria.ttf"),
// });

// Font.register({
//   family: "Cambria-Bold",
//   src: require("../fonts/cambriab.ttf"),
// });

// Font.register({
//   family: "Times",
//   src: require("../fonts/times.ttf"),
// });

// Font.register({
//   family: "Seguisym",
//   src: require("../fonts/seguisym.ttf"),
// });

const styles = StyleSheet.create({
  threeColumn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
    borderBottom: "none",
  },
  threeBlueColumn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
    borderBottom: "none",
  },
  threeBlankColumn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  firstColumnText: {
    alignSelf: "center",
    alignItems: "flex-end",
    fontSize: 12,
    color: "black",
    // fontFamily: "Cambria",
  },
  secondColumnText: {
    alignItems: "flex-end",
    fontSize: 12,
    color: "black",
    // fontFamily: "Cambria",
    marginLeft: 1,
  },
  lastcolumnText: {
    alignSelf: "center",
    alignItems: "flex-end",
    fontSize: 11,
    color: "black",
    // fontFamily: "Cambria",
  },
  whiteColumnHeader: {
    alignSelf: "center",
    fontSize: 12,
    color: "white",
    // fontFamily: "Cambria-Bold",
  },

  secFirstColumn: {
    alignSelf: "center",
    fontSize: 14,
    // fontFamily: "Calibri-Bold",
  },
  secSecondColumn: {
    fontSize: 14,
    // fontFamily: "Calibri-Bold",
  },
  secThirdColumn: {
    fontSize: 9.25,
    alignSelf: "center",
    // fontFamily: "Seguisym",
  },
});

const ThreeColumnTable = ({ data, column1, column2, column3, type }) => {
  const data_shown_name = data?.ap_detail?.shown_name;
  const renderBackgroundColor = () => {
    switch (data_shown_name) {
      case "DCF":
        return "#D22B2B";
      case "DCFP":
        return "#FFBF00";
      case "DCK":
        return "#538DD5";
      case "DCGS":
        return "#0D6856";
      case "DCHO":
        return "#00b8ad";
      case "DCBA":
        return "#846c8c";
      default:
        return "#D22B2B";
    }
  };
  return (
    <>
      {type == "bankList" ? (
        <View style={styles.threeColumn}>
          <View style={{ flex: 2, borderRight: "1px solid black", padding: 1 }}>
            <Text style={styles.firstColumnText}>{column1}</Text>
          </View>
          <View style={{ flex: 8, padding: 1, borderRight: "1px solid black" }}>
            <Text style={styles.secondColumnText}>{column2}</Text>
          </View>
          <View style={{ flex: 2, padding: 1 }}>
            <Text style={styles.lastcolumnText}>{column3}</Text>
          </View>
        </View>
      ) : type == "salesInvList" ? (
        <View style={styles.threeBlankColumn}>
          <View style={{ flex: 2, padding: 2 }}>
            <Text style={styles.secFirstColumn}>{column1}</Text>
          </View>
          <View style={{ flex: 4, padding: 2 }}>
            <Text style={styles.secSecondColumn}>{column2}</Text>
          </View>
          <View
            style={{
              height: 25,
              flex: 1,
              padding: 2,
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            {/* <Text style={styles.secThirdColumn}>{column3}</Text> */}
            {column3 === "yes" && <Image src={TickIcon} />}
          </View>
          <View style={{ flex: 5 }}></View>
        </View>
      ) : type == "bankListHeader" ? (
        <View
          style={[
            styles.threeBlueColumn,
            { backgroundColor: renderBackgroundColor() },
          ]}
        >
          <View style={{ flex: 2, borderRight: "1px solid black", padding: 1 }}>
            <Text style={styles.whiteColumnHeader}>{column1}</Text>
          </View>
          <View style={{ flex: 8, padding: 1, borderRight: "1px solid black" }}>
            <Text style={styles.whiteColumnHeader}>{column2}</Text>
          </View>
          <View style={{ flex: 2, padding: 1 }}>
            <Text style={styles.whiteColumnHeader}>{column3}</Text>
          </View>
        </View>
      ) : (
        ""
      )}
    </>
  );
};

export default ThreeColumnTable;
