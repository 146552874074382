// 10
export function basicNumber(number) {
  const checkNumber = number ? number : 0;
  const numberWithoutCommas = checkNumber.toString().replace(/,/g, "");

  const newNumber = Number(numberWithoutCommas);

  const formattedNumber = parseFloat(newNumber).toFixed(0);

  return formattedNumber;
}

// 10.00
export function basicNumberTwo(number) {
  const checkNumber = number || 0;

  const numberWithoutCommas = checkNumber.toString().replace(/,/g, "");

  const formattedNumber = Number(numberWithoutCommas).toFixed(2);

  return formattedNumber;
}

// 1,000.00
export function formatNumber(number) {
  const checkNumber = number ? String(number) : "0";

  if (checkNumber.includes(",")) {
    let numberString = checkNumber.replace(/,/g, "");
    let [integerPart, decimalPart] = numberString.split(".");

    if (decimalPart) {
      decimalPart = decimalPart.substring(0, 2); // Take only first two digits
    } else {
      decimalPart = "00"; // If no decimal part, add '.00'
    }

    const formattedNumber = parseFloat(
      integerPart + "." + decimalPart
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  } else {
    let numberString = checkNumber.replace(/,/g, "");

    let [integerPart, decimalPart] = numberString.split(".");

    if (decimalPart) {
      decimalPart = decimalPart.substring(0, 2); // Take only first two digits
    } else {
      decimalPart = "00"; // If no decimal part, add '.00'
    }

    const formattedNumber = parseFloat(
      integerPart + "." + decimalPart
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  }
}

export function formatNumberTwo(number) {
  const newNumber = basicNumberTwo(number);
  const checkNumber = number ? String(newNumber) : "0";

  if (checkNumber.includes(",")) {
    let numberString = checkNumber.replace(/,/g, "");
    let [integerPart, decimalPart] = numberString.split(".");

    if (decimalPart) {
      decimalPart = decimalPart.substring(0, 2); // Take only first two digits
    } else {
      decimalPart = "00"; // If no decimal part, add '.00'
    }

    const formattedNumber = parseFloat(
      integerPart + "." + decimalPart
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  } else {
    let numberString = checkNumber.replace(/,/g, "");

    let [integerPart, decimalPart] = numberString.split(".");

    if (decimalPart) {
      decimalPart = decimalPart.substring(0, 2); // Take only first two digits
    } else {
      decimalPart = "00"; // If no decimal part, add '.00'
    }

    const formattedNumber = parseFloat(
      integerPart + "." + decimalPart
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  }
}

// Check if the number has a decimal part of zero
export function checkFormatNumber(number) {
  const checkNumber = Number(number);
  if (checkNumber % 1 === 0) {
    return checkNumber.toString();
  } else {
    return checkNumber.toFixed(2);
  }
}

// 1k , 10k
export function formatNumberDecimal(number) {
  const checkNumber = number || 0;
  const numberWithoutCommas = checkNumber.toString().replace(/,/g, "");

  const units = ["k", "M", "B", "T", "P", "E", "Z", "Y"];
  const newNumber = Number(numberWithoutCommas);

  for (let i = units.length - 1; i >= 0; i--) {
    const decimal = Math.pow(1000, i + 1);

    if (Math.abs(newNumber) >= decimal) {
      const formattedNumber = (newNumber / decimal).toFixed(2);
      return formattedNumber + units[i];
    }
  }

  return newNumber.toFixed(2); // 小于1000时直接返回数字，保留两位小数
}

// 0.23 => 1 , 0 => 0 , 0.9 => 1
export function customRound(number) {
  const checkNumber = Number(number);
  if (checkNumber % 1 !== 0) {
    return Math.ceil(checkNumber);
  } else {
    return checkNumber;
  }
}

// generate amount ( 12 = [12, 20, 20, 50])
export function generateAmounts(amount) {
  const multiples = [5, 10, 20, 50, 100];
  let amounts = [amount];

  // 找到比amount大的下一个倍数
  for (let multiple of multiples) {
    let nextAmount = Math.ceil(amount / multiple) * multiple;
    if (!amounts.includes(nextAmount)) {
      amounts.push(nextAmount);
    }
  }

  // 添加最后一个1000
  // if (!amounts.includes(1000)) {
  //   amounts.push(1000);
  // }

  // 确保返回列表中包含6个金额
  while (amounts.length < 6) {
    amounts.push(amounts[amounts.length - 1] + multiples[0]);
  }

  return amounts.slice(0, 4); // 保证返回结果只有6个元素
}
