import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import TwoColumnTable from "./two.column.table";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "Inter-Bold",
//   src: require("../fonts/Inter-Bold.ttf"),
// });

const styles = StyleSheet.create({
  firstContainer: {
    display: "flex",
    marginTop: 8,
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
  },
  firstSubContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    borderBottom: "1px solid black",
  },
  firstContainerText: {
    fontSize: 8,
    fontWeight: 100,
    //transform: [{ scaleX: 30 }],
    // fontFamily: "Inter",
  },
  firstContainerSubText: {
    fontSize: 8,
    marginLeft: 2,
    fontWeight: 100,
    //transform: [{ scaleX: 30 }],
    // fontFamily: "Inter",
  },
});

const CommissionFirstTable = ({ data }) => {
  return (
    <div>
      <View style={styles.firstContainer}>
        <View style={{ flex: 4, borderRight: "1px solid black", padding: 0.5 }}>
          <Text style={styles.firstContainerText}>SALES CATEGORY :</Text>
        </View>
        <View style={{ flex: 8, padding: 0.5 }}>
          <Text style={styles.firstContainerSubText}>
            {data?.service_charge != "0.00" ? data?.service_charge + " " : ""}
            {/* {data?.service_charge === "0.00" ? "CASH BUYER" : "LOAN BUYER"} */}
            {data?.service_charge === "3.00"
              ? "Cash Buyer"
              : data?.service_charge === "8.00"
                ? "Loan Buyer"
                : data?.car_type == "used_car"
                  ? "Used Car - DCH Automobile"
                  : "Ready Stock"}
          </Text>
        </View>
      </View>
      <View style={styles.firstSubContainer}>
        <View style={{ flex: 4, borderRight: "1px solid black", padding: 0.5 }}>
          <Text style={styles.firstContainerText}>SALES PERSON :</Text>
        </View>
        <View style={{ flex: 8, padding: 0.5, gap: 0 }}>
          <Text style={styles.firstContainerSubText}>
            {data?.sales_agent_name}
          </Text>
        </View>
      </View>
      <View style={styles.firstSubContainer}>
        <View style={{ flex: 4, borderRight: "1px solid black", padding: 0.5 }}>
          <Text style={styles.firstContainerText}>VEHICLE MODEL :</Text>
        </View>
        <View style={{ flex: 8, padding: 0.5, gap: 0 }}>
          <Text style={styles.firstContainerSubText}>
            {data?.vehicle_model}
          </Text>
        </View>
      </View>
      <View style={styles.firstSubContainer}>
        <View style={{ flex: 4, borderRight: "1px solid black", padding: 0.5 }}>
          <Text style={styles.firstContainerText}>CHASSIS NO :</Text>
        </View>
        <View style={{ flex: 8, padding: 0.5, gap: 0 }}>
          <Text style={styles.firstContainerSubText}>
            {data?.vehicle_chasis_no}
          </Text>
        </View>
      </View>
      <View style={styles.firstSubContainer}>
        <View style={{ flex: 4, borderRight: "1px solid black", padding: 0.5 }}>
          <Text style={styles.firstContainerText}>CUSTOMER NAME :</Text>
        </View>
        <View style={{ flex: 8, padding: 0.5, gap: 0 }}>
          <Text style={styles.firstContainerSubText}>
            {data?.personal_name}
          </Text>
        </View>
      </View>
      <View style={styles.firstSubContainer}>
        <View style={{ flex: 4, borderRight: "1px solid black", padding: 0.5 }}>
          <Text style={styles.firstContainerText}>SUBMISSION DATE :</Text>
        </View>
        <View style={{ flex: 8, padding: 0.5, gap: 0 }}>
          <Text style={styles.firstContainerSubText}>
            {data?.commission_sub?.date ? data?.commission_sub?.date : ""}
          </Text>
        </View>
      </View>
    </div>
  );
};

export default CommissionFirstTable;
