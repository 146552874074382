import React, { useMemo } from "react";
import { Avatar, Tooltip } from "components/ui";
import acronym from "utils/acronym";
import PropTypes from "prop-types";
import useTwColorByName from "utils/hooks/useTwColorByName";

const UsersAvatarGroup = (props) => {
  const {
    avatarGroupProps,
    avatarProps,
    imgKey,
    nameKey,
    onAvatarClick,
    users,
    user_name,
    ...rest
  } = props;

  const bgColor = useTwColorByName();

  const defaultAvatarProps = useMemo(() => {
    return {
      shape: "circle",
      size: 30,
      className: "cursor-pointer",
      ...avatarProps,
    };
  }, [avatarProps]);

  const handleAvatarClick = (avatar) => {
    onAvatarClick?.(avatar);
  };

  return (
    <Avatar.Group
      omittedAvatarTooltip
      omittedAvatarProps={defaultAvatarProps}
      chained
      {...avatarGroupProps}
      {...rest}
    >
      {users.map((elm, index) => (
        <Tooltip
          key={"a_vatar" + index}
          title={user_name ? user_name : elm?.name ? elm?.name : "-"}
        >
          <Avatar
            {...defaultAvatarProps}
            // className={`${elm[imgKey] ? "" : bgColor(elm[nameKey])} ${defaultAvatarProps.className}`}
            src={elm?.url ? elm?.url : elm?.profile_picture[0]?.url}
            onClick={() => handleAvatarClick(elm)}
          >
            {acronym(elm.name)}
          </Avatar>
        </Tooltip>
      ))}
    </Avatar.Group>
  );
};

UsersAvatarGroup.defaultProps = {
  avatarProps: {},
  avatarGroupProps: {},
  users: [],
  nameKey: "name",
  imgKey: "img",
};

UsersAvatarGroup.propTypes = {
  users: PropTypes.array,
  avatarProps: PropTypes.object,
  avatarGroupProps: PropTypes.object,
  nameKey: PropTypes.string,
  imgKey: PropTypes.string,
};

export default UsersAvatarGroup;
