import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import TwoColumnTable from "./two.column.table";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "Inter-Bold",
//   src: require("../fonts/Inter-Bold.ttf"),
// });

const styles = StyleSheet.create({
  twoColumnBorder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
  },
  columnText: {
    alignItems: "end",
    fontSize: 13,
    color: "black",
    // fontFamily: "Calibri",
  },
});

const InsuranceTable = ({ data }) => {
  const formatCash = (cash) => {
    if (cash) {
      const formatTotal = Number(cash.toFixed(2));
      return formatTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return "-";
  };

  const renderWindscreen = (detail, remark, amount) => {
    if (detail === "yes") {
      if (remark && amount) {
        return `Yes - ${remark} RM${formatCash(amount)}`;
      } else if (remark) {
        return `Yes - ${remark}`;
      }
      return "Yes";
    } else {
      return "No";
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Handle cases where string is empty or undefined
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const fullAddress = `${data?.address ?? " "} ${data?.postcode ?? " "} ${data?.city ?? " "} ${data?.state ?? " "}`;

  return (
    <div>
      <TwoColumnTable column1="Customer Name" column2={data?.personal_name} />
      <TwoColumnTable column1="I/C No." column2={data?.identity_no} />
      <TwoColumnTable
        column1="CLIENT CONTACT"
        column2={data?.personal_contact}
      />
      <TwoColumnTable column1="CLIENT ADDRESS" column2={fullAddress} />
      <TwoColumnTable
        column1="VEHICLE MODEL/MAKE"
        column2={`${data?.vehicle_model ? data?.vehicle_model : " "} ${
          data?.vehicle_make ? `/ ${data?.vehicle_make}` : ""
        }`}
      />
      <TwoColumnTable column1="YEAR MAKE" column2={data?.vehicle_year} />
      <TwoColumnTable column1="CUBIC CAPACITY" column2={data?.vehicle_cc} />
      <TwoColumnTable column1="ENGINE NO" column2={data?.vehicle_engine_no} />
      <TwoColumnTable column1="CHASSIS NO" column2={data?.vehicle_chasis_no} />
      <TwoColumnTable
        column1="SELLING PRICE (VSO)"
        column2={`RM ${
          data?.insurance?.selling_price
            ? formatCash(data?.insurance?.selling_price)
            : data?.vso?.retail_price_of_vahicle
              ? formatCash(data?.vso?.retail_price_of_vahicle)
              : ""
        }`}
      />
      <TwoColumnTable
        column1={`SUM INSURED COVERAGE ${
          data?.insurance?.sum_insurance_coverage_percentage
            ? data.insurance.sum_insurance_coverage_percentage
            : 0
        } %`}
        column2={`RM ${
          data?.insurance?.sum_insurance_coverage_price
            ? data.insurance.sum_insurance_coverage_price
            : 0
        }`}
      />
      <TwoColumnTable
        column1="NCD%"
        column2={`${data?.insurance?.ncd ? data.insurance.ncd : " "}`}
      />
      <TwoColumnTable
        column1="WINDSCREEN COVERAGE (WITH /WITHOUT) TINTED"
        column2={`${
          // data?.insurance?.windscreen_coverage_with_tinted == "yes"
          //   ? data?.insurance?.windscreen_coverage_with_tinted +
          //     " - " +
          //     data?.insurance?.windscreen_detail
          //     ? data?.insurance?.windscreen_detail
          //     : ""
          //   : "no"
          renderWindscreen(
            data?.insurance?.windscreen_coverage_with_tinted,
            data?.insurance?.windscreen_detail,
            data?.insurance?.windscreen_amount
          )
        }`}
      />

      <TwoColumnTable
        column1="Correspondence Address"
        column2={`${data?.insurance?.correspondence_address ? data.insurance.correspondence_address : " "}`}
      />

      <TwoColumnTable
        column1="Buyer Type"
        column2={capitalizeFirstLetter(data?.insurance?.buyer_type)}
      />

      <TwoColumnTable
        column1="Takaful Insurance"
        column2={capitalizeFirstLetter(data?.insurance?.takaful_insurance)}
      />

      <View style={styles.twoColumnBorder}>
        <View style={{ flex: 5, borderRight: "1px solid black", padding: 1 }}>
          <Text style={styles.columnText}>CASH OR LOAN (BANK)</Text>
        </View>
        <View style={{ flex: 7, padding: 1 }}>
          <Text style={styles.columnText}>{data?.bank_loan_name}</Text>
        </View>
      </View>
    </div>
  );
};

export default InsuranceTable;
